import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../images/brand-header-logo.png";

import { login, saveTokenInLocalStorage } from "../../services/AuthService";
import Select from "react-select";

import { useForm, Controller } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import toast from "react-hot-toast";
export const zodValidationSchema = z.object({
  userType: z.object({
    value: z.string().min(1, { message: "User Type is required" }),
    label: z.string(),
  }),
  email: z.string().trim().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  password: z
    .string()
    .trim()
    .min(6, { message: "Password must be at least 6 characters" }),
});
const Login = (props) => {
  const [apiError, setApiError] = useState("");
  const options = [
    { value: "Pharmacy", label: "Pharmacy" },
    { value: "Lab", label: "Lab" },
  ];

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(zodValidationSchema),
  });

  const onSubmit = (data) => {

    login(data)
      .then((response) => {
        console.log(response, "login");
        saveTokenInLocalStorage(response.data.value.token);
        localStorage.setItem("anyTimeHospitalType", response.data.data.type);
        localStorage.setItem("anyTimeHospitalName", response.data.data.pharmacyName ?  response.data.data.pharmacyName : response.data.data.labName );
        toast.success("Login Successfully!");
        props.history.push("/");
      })
      .catch((error) => {
        console.log(error.response.data.message, "Login error");
        setApiError(error.response.data.message);
      });
  };

  return (
    <div className="login-form-bx">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 col-md-7 box-skew d-flex">
            <div className="authincation-content">
              <div className="mb-4">
                <h3 className="mb-1 font-w600">Welcome Back</h3>
                <p className="">Sign in by entering information below</p>
              </div>
              {apiError && (
                <div
                  role="alert"
                  className="fade alert-dismissible fade show alert alert-danger show"
                >
                  {apiError}
                </div>
              )}

              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong> Select User Type </strong>
                  </label>

                  <Controller
                    name="userType"
                    control={control}
                    defaultValue={options[0]}
                    rules={{ required: "User Type is required" }}
                    render={({ field }) => (
                      <Select
                        id="select"
                        // placeholder="Select User Type"
                        value={field.value}
                        onChange={(selectedOption) =>
                          field.onChange(selectedOption)
                        }
                        options={options}
                        isSearchable={false}
                        styles={{
                          placeholder: (provided) => ({
                            ...provided,
                            color: "gray",
                          }),
                        }}
                        placeholder="Select user type"
                      />
                    )}
                  />
                  {errors.userType && (
                    <div className="text-danger fs-12">
                      {errors.userType?.value?.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Email</strong>
                  </label>
                  <input
                    id="email"
                    type="email"
                    placeholder="Enter email"
                    className="form-control"
                    {...register("email")}
                  />
                  {errors.email && (
                    <div className="text-danger fs-12">
                      {errors.email?.message}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label className="mb-2 ">
                    <strong className="">Password</strong>
                  </label>
                  <input
                    id="password"
                    type="password"
                    placeholder="Enter password"
                    className="form-control"
                    {...register("password")}
                  />
                  {errors.password && (
                    <div className="text-danger fs-12">
                      {errors.password?.message}
                    </div>
                  )}
                </div>
                <div className="text-center">
                  <button type="submit" className="btn btn-primary btn-block">
                    Sign In
                  </button>
                </div>
              </form>
              <div className="new-account mt-3 ">
                <p>
                  Don't have an account?{" "}
                  <Link className="text-primary" to="/page-register">
                    Sign up
                  </Link>
                </p>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-5 d-flex box-skew1">
            <div className="inner-content align-self-center">
              <Link className="login-logo">
                <img src={logo} alt="" className="logo-text ml-1"/>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);
