import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

/// Image
import profile from "../../../images/user-icon3.png";
import { Dropdown } from "react-bootstrap";
import LogoutPage from "./Logout";
import ChangePassword from "../../modal/ChangePassword";
import { useDispatch, useSelector } from "react-redux";
import { profileUpdateAction } from "../../../store/actions/AuthActions";

const Header = ({ onNote }) => {
  const dispatch = useDispatch();
  const loginType = localStorage.getItem("anyTimeHospitalType");
  const [changePassword, setChangePassword] = useState(false);
  const [userDetails, setUserDetails] = useState(localStorage.getItem("anyTimeHospitalName"));
  const profileUpdate = useSelector(state=> state.auth.profileUpdate);


  useEffect(() => {
    if(profileUpdate){
     setUserDetails(localStorage.getItem("anyTimeHospitalName"));
     dispatch(profileUpdateAction(false));
    }
 }, [profileUpdate])

  return (
    <>
      <ChangePassword
        password={changePassword}
        onHide={() => setChangePassword(false)}
      />
      <div className="header" style={{ zIndex: "11" }}>
        <div className="header-content">
          <nav className="navbar navbar-expand">
            <div className="collapse navbar-collapse justify-content-between">
              <div className="header-left"></div>
              <ul className="navbar-nav header-right">
                <Dropdown as="li" className="nav-item dropdown header-profile">
                  <Dropdown.Toggle
                    variant=""
                    as="a"
                    className="i-false c-pointer nav-link"
                    to=""
                    role="button"
                    data-toggle="dropdown"
                  >
                    <div className="header-info">
                      <span>
                        {userDetails}
                      </span>
                    </div>
                    <img src={profile} width={20} alt="" />
                  </Dropdown.Toggle>
                  <Dropdown.Menu
                    align="right"
                    className="dropdown-menu dropdown-menu-right"
                  >
                    {loginType == "Pharmacy" && (
                      <Link
                        to="/app-profile-pharma"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ml-2">Profile</span>
                      </Link>
                    )}
                    {loginType == "Lab" && (
                      <Link
                        to="/app-profile-lab"
                        className="dropdown-item ai-icon"
                      >
                        <svg
                          id="icon-user1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="text-primary"
                          width={18}
                          height={18}
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
                          <circle cx={12} cy={7} r={4} />
                        </svg>
                        <span className="ml-2">Profile </span>
                      </Link>
                    )}
                    <Link
                      className="dropdown-item ai-icon"
                      onClick={() => setChangePassword(true)}
                      style={{ backgroundColor: "#fff" }}
                    >
                      <svg
                      id="icon-inbox"
                      xmlns="http://www.w3.org/2000/svg"
                      className="text-success"
                      width={18}
                      height={18}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
                      <polyline points="22,6 12,13 2,6" />
                    </svg>
                      <span className="ml-2">Change Password </span>
                    </Link>
                    <LogoutPage />
                  </Dropdown.Menu>
                </Dropdown>
              </ul>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Header;
